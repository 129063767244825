import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  NgZone,
  OnDestroy,
  ElementRef,
  HostListener,
  Inject,
} from '@angular/core';
import { DOCUMENT, formatDate } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { takeUntil, filter as rxFilter, tap } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { shops, map, filter } from './shop-selector.component.animations';
import { KEYS } from 'sf-logger-javascript';

// COMPONENTS
import { ModalComponent } from '../modal/modal.component';
import { BaseComponent } from 'app/base/base.component';

// SERVICES
import { WINDOW } from '../service/window.service';
import { ShopInfoService } from '../service/shop-info.service';
import { ServiceClientsService } from '../service/service-clients.service';
import { GoogleSearchService } from '../service/google-search.service';
import { DataAnalyticsService } from '../service/data-analytics.service';
import { StorageService } from '../service/storage.service';
import { UrlResolverService } from '../service/url-resolver.service';
import { SearchResultsService } from '../service/search-results.service';
import { SearchResultsResponse } from '../model/search-results-response';
import { ShopSelectorManagementService } from 'app/service/shop-selector-management.service';
import { CoverageInfoService } from 'app/service/coverage-info.service';
import { FooterDisplayService } from 'app/service/footer-display.service';
import { RepairExperienceService } from 'app/service/repair-experience.service';
import { InteractionsService } from '../service/interactions.service';
import { SFLoggerService } from 'app/service/sf-logger.service';

// MODELS
import { RepairAssignment } from '../model/repair-assignment.model';
import { LatLongModel } from '../model/latLong.model';
import { Shop } from '../model/shop.model';
import { UrlParams } from '../model/url-params.model';
import { ShopInfo } from '../model/shop-info.model';
import { Marker } from '../model/map-marker.model';
import { WindowReference } from '../model/window-reference.model';
import { Maintenance } from '../model/maintenance.model';
import { CoverageInfo } from 'app/model/coverage-info.model';
import { SecondaryAssignment } from 'app/model/secondary-assignment.model';
import { CookieService } from 'ngx-cookie-service';

// ENUMS
import { ViewState } from '../enums/view-state';
import { SFLoggerFields } from 'app/enums/sf-logger-fields';
import { SFLoggerMessageIds } from 'app/enums/sf-logger-message-ids';

@Component({
  selector: 'app-shop-selector',
  templateUrl: './shop-selector.component.html',
  styleUrls: ['./shop-selector.component.css'],
  animations: [shops, map, filter],
})
export class ShopSelectorComponent
  extends BaseComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('SelectService', { static: false }) selectServiceModal: ModalComponent;
  @ViewChild('Confirmation', { static: true }) confirmationModal: ModalComponent;
  @ViewChild('B2B', { static: false }) B2BModal: ModalComponent;
  @ViewChild('PCARedirect', { static: false }) pcaModal: ModalComponent;
  @ViewChild('ExpandSearch', { static: false }) expandSearchModal: ModalComponent;
  @ViewChild('confirmationFocusElem', { static: true }) confirmationFocusElem: ElementRef;
  @ViewChild('selectServiceFocusElem', { static: false }) selectServiceFocusElem: ElementRef;
  @ViewChild('b2bFocusElem', { static: false }) b2bFocusElem: ElementRef;
  @ViewChild('MockFooter', { static: true }) public mockFooter: ElementRef;
  @ViewChild('showList', { static: false }) showListDiv: ElementRef;
  @ViewChild('toggleb2b', { static: false }) toggleb2b: ElementRef;
  @ViewChild('shopSelector', { static: true }) public shopSelectorRef: ElementRef;

  urlParams: UrlParams;
  private ngUnsubscribe = new Subject();
  private nextPageSubject = new Subject();
  private providerResults: SearchResultsResponse;

  medallia: any;
  selectServiceModalHidden = true;
  claimNumber$ = new Observable<string>();
  isViewStatic = false;
  youAreHereMarker: Marker;
  lat: number;
  lng: number;
  isZipCodeSearch: boolean;
  isNameSearch: boolean;
  zip: string;
  coords: LatLongModel;
  selectedShop: Shop;
  activeShopIndex: number;
  currentPageShopIndex: number;
  geosearchResults: any;
  geolocationPosition: any;
  locations: any;
  sortedShops = [];
  isDrawerOpen: boolean;
  isSidebarVisabile = true;
  isNoShopsFoundVisible = true;
  isMobile: boolean;
  isDesktop: boolean;
  pageState;
  gridsize: string;
  pageWidth: number;
  heightPage: number;
  pageOrientation: string;
  shopToUseForClaim: Shop;
  isFailedConfirmation = false;
  shops: Shop[];
  filteredShops: Shop[] = [];
  filteredCertShops: Shop[] = [];
  selectedCerts: string[] = [];
  filteredNationalProviderShops: Shop[] = [];
  isPCARedirect: boolean;
  isShopNotFound: boolean;
  isCertShopsNotFound: boolean;
  isCertShopsSearch: boolean;
  isOtherCountrySearch: boolean;
  isIIOFlow: boolean;
  searchTermError: string;
  searchRadiusError: string;
  isExpandSearch: boolean;
  currentPage = 'ra-search-results';
  maintenance$: ReplaySubject<Maintenance>;
  shouldDisplaySelectAShopLater = false;
  isDeviceDesktop: boolean;
  isDeviceMobile: boolean;
  isConfirmation: boolean;
  isPanMoved: boolean;
  activeElem: HTMLElement;
  count: number;
  filteredCount: number;
  filterNo: number = 0;
  resultFilterNo: number = 0;
  reset: boolean = true;
  defaultToggle: boolean;
  isScrolling;
  selectedSortBy = 'Featured';
  selectedSortByDrawer = 'Featured';
  selectedNationalProvider: boolean = false;
  verbiage$: Observable<string>;
  currentShop$: Observable<SecondaryAssignment>;
  assignmentCount = 1;
  oneX: any;
  b2bToggle = 'b2bToggleOff';
  certificates: string[];

  y = 0;
  dragBarPosition = 0;
  yOffset: number;
  heightHeader = 64;
  heightSearch = 58;
  heightControls = 40;
  heightLegal = 26;
  heightMap: number;
  heightList: number;

  pageCount: number;
  currentPageIndex: number;
  shopIndexStart: number;
  shopIndexEnd: number;

  coverageCode: string;
  coverageTitle: string;
  coverageValues: Map<String, String>;
  effectiveDate: string;

  vehicleMake: string;
  vehicleModel: string;
  vehicleYear: string;

  isEstimateAssist: boolean;

  showShopDetails = false;

  sortOrder = {
    value: 'PERFORMANCE', // default sort is by State Farm performance (tiers)
    label: 'Featured', // displayed to the user; the result of clicking "sort by"
  };

  shopCountPluralMapping = {
    count: {
      '=0': '',
      '=1': '',
      other: '',
    },
  };

  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    urlResolver: UrlResolverService,
    __zone: NgZone,
    router: Router,
    private googleSearchService: GoogleSearchService,
    private activateRoute: ActivatedRoute,
    private dataAnalyticsService: DataAnalyticsService,
    clientService: ServiceClientsService,
    searchResultsService: SearchResultsService,
    sfLoggerService: SFLoggerService,
    storageService: StorageService,
    private shopSelectorManagementService: ShopSelectorManagementService,
    private footerDisplayService: FooterDisplayService,
    windowRef: WindowReference,
    private deviceService: DeviceDetectorService,
    private shopInfoService: ShopInfoService,
    private coverageInfoService: CoverageInfoService,
    private interactionsService: InteractionsService,
    private repairExperienceService: RepairExperienceService,
    private cookieService: CookieService,
  ) {
    super(
      __zone,
      router,
      urlResolver,
      clientService,
      searchResultsService,
      windowRef,
      storageService,
      sfLoggerService,
    );
    this.urlParams = new UrlParams();
    this.activeShopIndex = 0;
    this.currentPageShopIndex = 0;
    this.currentPageIndex = 1;
    this.shopIndexStart = 0;
    this.shopIndexEnd = 3;
    this.oneX = this.window['oneX'];
  }

  ngOnInit() {
    this.effectiveDate = formatDate(new Date(), 'yyyy/MM/dd', 'en');
    const searchedResultFromSessionStorage = this.storageService.getSessionStorage('searchResult');
    this.isEstimateAssist = JSON.parse(this.storageService.getSessionStorage('isEstimateAssist'));
    this.urlResolver.populateQueryParams(this.activateRoute);
    this.checkForUrlParams(searchedResultFromSessionStorage);
    this.updateViewportInformation();
    this.getSearchResults(searchedResultFromSessionStorage);
    this.maintenance$ = this.clientService.callToMaintenanceService();
    this.role = this.urlResolver.getRole();
    this.isDeviceDesktop = this.deviceService.isDesktop();
    this.isDeviceMobile = this.deviceService.isMobile();
    this.shouldDisplaySelectAShopLater =
      this.urlParams.externalClaimId &&
      this.urlParams.externalClaimId.length > 0;
    this.shopSelectorManagementService.setIsPanMoved(false);

    this.shopSelectorManagementService.getIsPanMoved().subscribe(response => {
      this.isPanMoved = response;

      if (!this.isPanMoved) {
        this.resetViewPosition();
      }
    });
    this.pageCount = Math.ceil((this.sortedShops.length - 3) / 5) + 1;
    // Check if rental coverage is eligible
    if (this.urlParams.externalClaimId) {
      let participantId;
      setTimeout(() => {
        this.getCoverageDetail();
        this.getVehicleDetail();
        this.clientService
          .isEligibleForRentalAssignments().pipe(
            takeUntil(this.ngUnsubscribe)
          ).subscribe(isEligible => {
            this.storageService.setSessionStorage(
              'isEligibleForRentalAssignments',
              isEligible.toString()
            );
            if (!isEligible) {
              this.clientService
                .getParticipantsResponse().pipe(
                  takeUntil(this.ngUnsubscribe)
                ).subscribe(participants => {
                  // TODO: whether participants would have more than one participant since RE would only return one participant ID
                  participantId = participants[0].id;
                  this.repairExperienceService.getRentalAssignment(
                    this.urlParams.externalClaimId,
                    participantId,
                    this.urlParams.externalClientId
                  );
                  this.repairExperienceService
                    .isRentalCompleted().pipe(
                      takeUntil(this.ngUnsubscribe)
                    ).subscribe(isCompleted => {
                      this.storageService.setSessionStorage(
                        'isRentalCompleted',
                        isCompleted.toString()
                      );
                    });
                });
            }
          });
      }, 2000);
    }

    this.verbiage$ = this.clientService.getComplianceVerbiage();
    this.currentShop$ = this.clientService.getCurrentShopDetail();

    this.clientService.getCurrentShopDetail()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(res => {
        if (res) {
          this.assignmentCount = res.AssignmentCount + 1;
        }
      });
  }

  setAnalyticsScreenId(screenId: string) {
    if (screenId === 'sort-and-filter') {
      this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_SORT_FILTER_CLICKED, 'Customer has opened sort and filter drawer');
    }
    this.dataAnalyticsService.sendPageData(screenId);
    this.isDrawerOpen = true;
  }

  setBackScreenId() {
    this.dataAnalyticsService.sendPageData('search-results');
    this.isDrawerOpen = false;
  }

  setShopIndex(moreShops: boolean) {
    const totalShops = this.sortedShops.length;
    this.nextPageSubject.next();
    if (moreShops) {
      this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_MORE_SHOPS_CLICKED, 'Customer clicked more shops button');
      this.currentPageIndex += 1;
      this.shopIndexStart = this.shopIndexEnd;
      this.shopIndexEnd =
        this.currentPageIndex !== this.pageCount
          ? this.shopIndexEnd + 5
          : totalShops;
    } else {
      this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_PREV_SHOPS_CLICKED, 'Customer clicked back button for shop list');
      this.currentPageIndex -= 1;
      this.shopIndexEnd = this.currentPageIndex !== 1 ? this.shopIndexStart : 3;
      this.shopIndexStart =
        this.currentPageIndex !== 1 ? this.shopIndexStart - 5 : 0;
    }
    this.currentPageShopIndex = 0;

    const start = this.shopIndexStart;
    let end = this.shopIndexEnd;
    if (end > totalShops) {
      end = totalShops;
    }
    this.fetchShopDetailInformation(start, end);
  }

  fetchShopDetailInformation(start, end) {
    for (let i = start; i < end; i++) {
      if(!this.sortedShops[i].placeDetails){
        setTimeout(() => {
          this.googleSearchService.getplaceId(this.sortedShops[i], 0);
        }, 10);
      }

    }
  }

  onPanStart(event: any): void {
    this.yOffset = document.getElementById('list-resize-handle').offsetTop;
    this.shopSelectorManagementService.setIsPanMoved(true);
  }

  onPan(event: any): void {
    event.preventDefault();
    this.dragBarPosition = event.deltaY;
    this.y = this.yOffset + this.dragBarPosition;

    // TODO: determine why the math is not pixel perfect (thinking its related to element borders adding a few pixels of height)
    const OFFSET_TOP = 4;
    const OFFSET_BOTTOM = 1;

    if (
      this.y - this.heightHeader + OFFSET_TOP >= 0 &&
      this.y + this.heightHeader + this.heightControls + OFFSET_BOTTOM <
      this.heightPage
    ) {
      this.heightList = this.heightPage - this.heightHeader - this.y;
      this.heightMap = this.heightPage - this.heightHeader - this.heightList;
    }
  }

  onPanEnd(event: any): void {
    event.preventDefault();

    if (this.dragBarPosition < 0) {
      // pan up
      if (this.pageState === ViewState.MOBILE_PORTRAIT_MAPONLY) {
        // pan up to splitscreen
        this.pageState = ViewState.MOBILE_PORTRAIT_SPLITSCREEN;
      } else {
        // pan up to list only
        this.pageState = ViewState.MOBILE_PORTRAIT_LISTONLY;
      }
    } else {
      // pan down
      if (this.pageState === ViewState.MOBILE_PORTRAIT_SPLITSCREEN) {
        // pan down to map only
        this.pageState = ViewState.MOBILE_PORTRAIT_MAPONLY;
      } else {
        // pan down to splitscreen
        this.pageState = ViewState.MOBILE_PORTRAIT_SPLITSCREEN;
      }
    }
    this.dragBarPosition = 0;
  }

  resetViewPosition() {
    if (
      this.pageState === ViewState.MOBILE_PORTRAIT_MAPONLY ||
      this.pageState === ViewState.MOBILE_PORTRAIT_LISTONLY
    ) {
      this.pageState = ViewState.MOBILE_PORTRAIT_SPLITSCREEN;
    }
  }

  getDragBarVisiblility() {
    if (
      this.pageState === ViewState.MOBILE_PORTRAIT_SPLITSCREEN ||
      this.pageState === ViewState.MOBILE_PORTRAIT_MAPONLY ||
      this.pageState === ViewState.MOBILE_PORTRAIT_LISTONLY
    ) {
      return true;
    } else {
      return false;
    }
  }

  getMapChevronVisibility() {
    if (
      this.pageState !== ViewState.MOBILE_PORTRAIT_SPLITSCREEN &&
      this.pageState !== ViewState.MOBILE_PORTRAIT_MAPONLY &&
      this.pageState !== ViewState.MOBILE_PORTRAIT_MAPINTERMEDIATE &&
      this.pageState !== ViewState.MOBILE_PORTRAIT_LISTONLY
    ) {
      return true;
    } else {
      return false;
    }
  }

  getSearchResults(searchedResultFromSessionStorage): any {
    if (this.urlParams.isBookmarked) {
      // SPARKL Logging - RA Init from Bookmark
      let loggingDetails = [ ];

      if(this.urlParams.externalClaimId) {
        loggingDetails.push(
          KEYS.ENTRYPOINT, SFLoggerFields.SEARCH_RESULTS_PAGE,
          KEYS.CLAIM_ID, this.urlParams.externalClaimId,
          KEYS.CLIENT_ID, this.urlParams.externalClientId,
          SFLoggerFields.ROLE, this.urlParams.role,
          SFLoggerFields.SEARCH_ONLY, false,
          SFLoggerFields.USER_FLOW, SFLoggerFields.TRANSACTIONAL_FLOW
        )

        if(this.urlParams.participantId) {
          loggingDetails.push(
            SFLoggerFields.PARTICIPANT_ID, this.urlParams.participantId
          )
        }
      } else {
        loggingDetails.push(
          KEYS.ENTRYPOINT, SFLoggerFields.SEARCH_RESULTS_PAGE,
          SFLoggerFields.SEARCH_ONLY, true,
          SFLoggerFields.USER_FLOW, SFLoggerFields.SEARCH_ONLY_FLOW,
        )
      }

      this.sfLoggerService.setContext(loggingDetails);
      this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_INITIALIZED, "Initialize Repair Assistant");

      const searched = this.urlResolver.getSearchedTermAndRadius();
      this.searchResultsService.setSearchedCriteria(
        searched.searchTerm,
        searched.radius
      );
      this.searchResultsService.getRepairShops(this.urlParams.searchTerm);
      this.searchResultsService.isSearchReturned().subscribe(r => {
        if (r && r.shouldChange) {
          this.providerResults = this.searchResultsService.getSearchResultsResponse();
          this.setMapSearchResult(this.providerResults);
          this.searchResultsService.isSearchReturned().next();
        }
      });
    } else if (this.urlParams.isRefreshed) {
      this.urlParams.isRefreshed = false;
      this.providerResults = JSON.parse(searchedResultFromSessionStorage);
      this.searchResultsService.setSearchResultsResponse(this.providerResults);
      this.setMapSearchResult(this.providerResults);
    } else {
      this.providerResults = this.searchResultsService.getSearchResultsResponse();
      this.setMapSearchResult(this.providerResults);
    }
  }

  private setMapSearchResult(providerResults: SearchResultsResponse) {
    this.shops = providerResults.shops;
    this.isZipCodeSearch = providerResults.isZipCodeSearch;
    this.isNameSearch = providerResults.isNameSearch;
    this.zip = providerResults.zipCode;
    this.reset = true;
    this.sortedShops = this.getAvailableShops();
    this.count = this.sortedShops.length;
    this.filteredCount = this.shops.length;
    this.getMapInfo();
    this.getAvailableCertificates();
    this.isShopNotFound = providerResults.displayShopsNotFound;
    this.isCertShopsNotFound = providerResults.displayCertShopsNotFound;
    this.isCertShopsSearch = providerResults.isCertShopSearch;
    this.isOtherCountrySearch = providerResults.isOtherCountrySearch;
    this.isIIOFlow = providerResults.displayIIOMessage;
    this.isExpandSearch = providerResults.isRadiusExpanded;
    let radius = providerResults.searchRadius;
    // for Mobile view error message should say Miles instead of Mi
    if (radius && !/Miles/g.test(radius)) {
      radius = radius.split(' ')[0] + ' Miles';
    }
    this.searchRadiusError = radius;
    this.searchTermError = providerResults.searchTerm;

    let searchResultMessage: String;
    let eventName;
    if (this.sortedShops.length === 0) {
      searchResultMessage = 'no-shops-error';
      eventName = 'Repair Assistant no shops error';
    } else if (this.isShopNotFound && this.sortedShops.length > 0) {
      searchResultMessage = 'out-of-program-error';
      eventName = 'Repair Assistant out of program error';
    } else if (
      !this.isShopNotFound &&
      this.sortedShops.length > 0 &&
      this.isExpandSearch
    ) {
      searchResultMessage = 'expanded-search-results';
      eventName = 'Repair Assistant expanded search';
    }

    if (searchResultMessage !== undefined) {
      this.dataAnalyticsService.sendPageData(searchResultMessage);
      this.interactionsService.sendInteractions(eventName, 'Auto Repair');
    }

  }

  /*
   * this.screensize | Store the current screensize to a variable.
   * this.isMobile | triggers hide / show animations
   * this.isDesktop | triggers hide / show animations
   */
  @HostListener('window:resize', ['$event']) onResize(event) {
    this.updateViewportInformation();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset =
      this.window.pageYOffset ||
      this.document.documentElement.scrollTop ||
      this.document.body.scrollTop ||
      0;

    this.window.clearTimeout(this.isScrolling);
    this.isScrolling = setTimeout(
      function (mockFooter, footerDisplayService) {
        if (window.pageYOffset < 40 && mockFooter.nativeElement.hidden) {
          mockFooter.nativeElement.hidden = false;
          footerDisplayService.changeFooterDisplay(false);
        }
      },
      300,
      this.mockFooter,
      this.footerDisplayService
    );
  }

  private updateViewportInformation() {
    const DXL_GRID_XS = 375;
    const DXL_GRID_SM = 540;
    const DXL_GRID_MD = 720;
    const DXL_GRID_LG = 960;
    const DXL_GRID_XL = 1140;

    this.pageWidth = this.window.innerWidth;
    this.heightPage = this.window.innerHeight;

    // determine orientation
    if (this.pageWidth - this.heightPage > 0) {
      this.pageOrientation = 'landscape';
    } else {
      this.pageOrientation = 'portrait';
    }

    // determine viewport size
    switch (true) {
      case this.pageWidth < DXL_GRID_XS:
        this.gridsize = 'XS';
        break;
      case this.pageWidth < DXL_GRID_SM:
        this.gridsize = 'SM';
        break;
      case this.pageWidth < DXL_GRID_MD:
        this.gridsize = 'MD';
        break;
      case this.pageWidth < DXL_GRID_LG:
        this.gridsize = 'LG';
        break;
      case this.pageWidth < DXL_GRID_XL:
        this.gridsize = 'XL';
        break;
      default:
        this.gridsize = 'XL';
        break;
    }

    if (
      'XS' === this.gridsize ||
      'SM' === this.gridsize ||
      'MD' === this.gridsize ||
      ViewState.MOBILE_LANDSCAPE_MAPONLY === this.pageState
    ) {
      this.isMobile = true;
      this.isDesktop = false;
    } else {
      this.isMobile = false;
      this.isDesktop = true;
    }

    if (
      this.isMobile &&
      this.isSidebarVisabile &&
      this.pageOrientation === 'portrait'
    ) {
      if (
        this.pageState === ViewState.MOBILE_PORTRAIT_MAPONLY ||
        this.pageState === ViewState.MOBILE_LANDSCAPE_SPLITSCREEN ||
        this.pageState === ViewState.DESKTOP_SPLITSCREEN ||
        this.pageState === undefined
      ) {
        this.pageState = ViewState.MOBILE_PORTRAIT_MAPINTERMEDIATE;
        setTimeout(() => {
          this.pageState = ViewState.MOBILE_PORTRAIT_SPLITSCREEN;
        }, 350);
      }
    }
    if (
      this.isMobile &&
      !this.isSidebarVisabile &&
      this.pageOrientation === 'portrait'
    ) {
      this.pageState = ViewState.MOBILE_PORTRAIT_MAPINTERMEDIATE;
      setTimeout(() => {
        this.pageState = ViewState.MOBILE_PORTRAIT_MAPONLY;
      }, 250);
    }
    if (
      this.isMobile &&
      this.isSidebarVisabile &&
      this.pageOrientation === 'landscape'
    ) {
      this.pageState = ViewState.MOBILE_LANDSCAPE_SPLITSCREEN;
    }
    if (
      this.isMobile &&
      !this.isSidebarVisabile &&
      this.pageOrientation === 'landscape'
    ) {
      this.pageState = ViewState.MOBILE_LANDSCAPE_MAPONLY;
    }
    if (this.isDesktop && this.isSidebarVisabile) {
      this.pageState = ViewState.DESKTOP_SPLITSCREEN;
    }
    if (this.isDesktop && !this.isSidebarVisabile) {
      this.pageState = ViewState.DESKTOP_MAPONLY;
    }
  }

  private checkForUrlParams(searchedResultFromSessionStorage) {
    /*
     *  NO QUERY =>     /search
     *  WITH QUERY  =>  /search;query=Atlanta;sortby=Distance
     */
    this.urlParams = this.urlResolver.getUrlParams();
    if (this.isBookMarked(searchedResultFromSessionStorage)) {
      this.urlParams.isBookmarked = true;
      this.urlParams.isRefreshed = false;
      this.initiateClientService();
    } else if (this.isRefreshed(searchedResultFromSessionStorage)) {
      this.urlParams.isRefreshed = true;
      this.urlParams.isBookmarked = false;
      this.initiateClientService();
    }
  }

  private initiateClientService() {
    if (this.urlParams.externalClaimId && this.urlParams.externalClientId) {
      this.clientService.initClientServices(
        this.urlParams.externalClaimId,
        this.urlParams.externalClientId
      );
    }
  }

  onGeolocation(coords: LatLongModel) {
    // TODO pipe this into the shop-map component
    this.coords = coords;
    // console.log('GEOSEARCH coords: ', coords);
  }

  onQuery(query: string) {
    // // TODO pipe this into the shop-map component
    // console.log('INFO: geosearching with query: ', query);
    this.geosearchResults = query;
  }

  onShopSelected(event, index) {
    const shop: any = event;
    this.selectedShop = shop;
  }

  onShopUseForClaim(event, index) {
    this.shopToUseForClaim = event;
    this.claimNumber$ = this.clientService.getClaimNumber();
    this.dataAnalyticsService.setShopIndex(index);
    this.activeElem = this.document.activeElement as HTMLElement;
    if (this.shopToUseForClaim.unavailable) {
      this.B2BModal.showAlternateModal('b2b-modal');
      setTimeout(() => this.b2bFocusElem.nativeElement.focus(), 0);
    } else {
      this.oneX.Modal.showModal(this, 'confirm-modal');
      setTimeout(() => this.confirmationFocusElem.nativeElement.focus(), 0);
    }
  }

  continueSearch() {
    this.B2BModal.hide();
  }

  useShopAnyways() {
    this.B2BModal.hide();
    this.oneX.Modal.showModal(this, 'confirm-modal');
    setTimeout(() => this.confirmationFocusElem.nativeElement.focus(), 0);
  }

  getVehicleDetail() {
    this.clientService
      .getVehicleDetail()
      .pipe(
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(
        vehicle  => {
          if (vehicle) {
            this.vehicleMake = vehicle.make;
            this.vehicleModel = vehicle.model;
            this.vehicleYear = vehicle.year;

          }
        }
      );
  }

  hideConfirmationModal() {
    this.oneX.Modal.hideModal();
    this.isFailedConfirmation = false;
    this.activeElem.focus();
  }

  onShopCardClicked(value) {
    this.activeShopIndex = value + this.shopIndexStart;
    this.currentPageShopIndex = value;
    this.selectedShop = this.sortedShops[this.activeShopIndex];
    this.showShopDetails = true;

    if (this.selectedShop.certifications) {
      this.dataAnalyticsService.setVehicleCert('yes');
    } else {
      this.dataAnalyticsService.setVehicleCert('no');
    }
    if (this.selectedShop.nationalProvider) {
      this.dataAnalyticsService.setNationalCert('yes');
    } else {
      this.dataAnalyticsService.setNationalCert('no');
    }
    this.dataAnalyticsService.sendPageData('search-results-detail-card');
  }

  toggleSidebarVisability() {
    this.isSidebarVisabile = !this.isSidebarVisabile;
    this.updateViewportInformation();
    if (!this.isSidebarVisabile && this.isMobile) {
      this.showListDiv.nativeElement.focus();
    }
  }

  toggleB2B() {
    // this flag used to toggle off from filter results page when it was on shop results page
    this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_SHOP_AVAILABILITY_TOGGLE_CLICKED, 'Customer clicked shop availability toggle');
    if(!this.defaultToggle){
      this.b2bToggle = this.b2bToggle === 'b2bToggleOff' ? 'b2bToggleOn' : 'b2bToggleOff';
      this.sortedShops = this.getAvailableShops();
      this.getMapInfo();
      this.count = this.sortedShops.length;
      if(this.reset) {
        this.filteredCount = this.shops.length;
      }
      this.activeShopIndex = 0;
      this.currentPageShopIndex = 0;
      this.currentPageIndex = 1;
      this.shopIndexStart = 0;
      this.shopIndexEnd = 3;
      this.pageCount = Math.ceil((this.sortedShops.length - 3) / 5) + 1;
    }
    this.defaultToggle = false;
  }

  sortByPerformance(e) {
    this.sortOrder.value = 'PERFORMANCE';
    this.sortOrder.label = 'Featured';
    this.selectedSortByDrawer = this.sortOrder.label;
  }

  sortByDistance(e) {
    if(e.target.checked){
      this.sortOrder.value = 'DISTANCE';
      this.sortOrder.label = 'Distance';
    } else{
      // this block used to handle reset and no filter criteria for default as performance sort
      this.sortOrder.value = 'PERFORMANCE';
      this.sortOrder.label = 'Featured';
    }
    this.selectedSortByDrawer = this.sortOrder.label;
  }

  getAvailableShops() {
    let availableShops = [];
    if(this.reset){
      // reset flag is used to handle results from shop results page and shop filters page
      if (this.b2bToggle === 'b2bToggleOn') {
        this.shops.forEach(shop => {
          if (!shop.unavailable) {
            availableShops.push(shop);
          }
        });
      } else {
        availableShops = this.shops;
      }
    } else{
      if (this.b2bToggle === 'b2bToggleOn') {
        this.getFilteredShops().forEach(shop => {
          if (!shop.unavailable) {
            availableShops.push(shop);
          }
        });
      } else {
        availableShops = this.getFilteredShops();
      }
    }

    return availableShops;
  }

  getAvailableCertificates() {
    let certificates = [];
    this.shops.forEach((shop) => {
      if (shop.certifications && shop.certifications.length > 0) {
        shop.certifications.forEach((certificate) => {
          certificates.push(Object.values(certificate)[0])
        })
      }
    }
    )
    let uniqueCertificates = [...new Set(certificates)];
    this.certificates = uniqueCertificates.sort();
  }

  onCertificateSelection(e) {
    let availableShops = this.shops;
    let matchedShops = []
    if (e.target.checked) {
      this.selectedCerts.push(e.target.value);
      this.filterNo = this.filterNo+1;
    } else {
      this.selectedCerts = this.selectedCerts.filter((cert) => {
        return cert !== e.target.value;
      })
      this.filterNo = this.filterNo-1;
    }
    if (this.selectedCerts.length > 0) {
      this.selectedCerts.forEach((cert) => {
        availableShops.forEach((shop) => {
          if (shop.certifications && shop.certifications.length > 0) {
            shop.certifications.forEach((certificate) => {
              if (Object.values(certificate)[0] === cert) {
                matchedShops.push(shop);
              }
            })
          }
        }
        )
      })
      const uniqueShops = [...new Map(matchedShops.map((shop) => [shop.estimatingId, shop])).values()];
      this.filteredCertShops = uniqueShops;
      if (this.selectedNationalProvider === true) {
        this.filteredShops = this.filteredCertShops.filter((shop) => {
          return shop.nationalProvider === true;
        });
      } else {
        this.filteredShops = this.filteredCertShops;
      }

    } else {
      if (this.selectedNationalProvider === true) {
        this.filteredShops = availableShops.filter((shop) => {
          return shop.nationalProvider === true;
        });
      } else {
        this.filteredShops = availableShops;
      }

    }
    this.filteredCount = this.filteredShops.length;
  }

  onNationalProviderSelection(e){
    let availableShops = this.shops;
    if(e.target.checked) {
      this.selectedNationalProvider = true;
      if(this.selectedCerts.length > 0) {
        this.filteredShops = this.filteredCertShops.filter((shop) => {
          return shop.nationalProvider === true;
        })

      } else {
        this.filteredNationalProviderShops = availableShops.filter((shop) => {
          return shop.nationalProvider === true;
        })
        this.filteredShops = this.filteredNationalProviderShops
      }
      this.filterNo = this.filterNo+1;
    } else {
      this.selectedNationalProvider = false;
      if(this.selectedCerts.length > 0) {
        this.filteredShops = this.filteredCertShops;
      } else {
        this.filteredShops = availableShops;
      }
      this.filterNo = this.filterNo-1;
    }
    this.filteredCount = this.filteredShops.length;
  }

  filterShops() {
    // Sort By Filter
    this.reset = false;
    this.sortedShops = this.getFilteredShops();
    this.getMapInfo();
    this.count = this.sortedShops.length;
    this.activeShopIndex = 0;
    this.currentPageShopIndex = 0;
    this.currentPageIndex = 1;
    this.shopIndexStart = 0;
    this.shopIndexEnd = 3;
    this.pageCount = Math.ceil((this.sortedShops.length - 3) / 5) + 1;
    this.nextPageSubject.next();
    this.selectedSortBy = this.sortOrder.label;
    if(this.b2bToggle === 'b2bToggleOn'){
      this.defaultToggle = true;
      this.b2bToggle = 'b2bToggleOff';
      this.toggleb2b.nativeElement.click();
    }
    this.dataAnalyticsService.setFilters(this.sortOrder.value, this.selectedCerts, this.selectedNationalProvider);
    this.resultFilterNo = this.filterNo
  }

  getFilteredShops() {
    let results: any;
    if (this.filteredCount === this.shops.length) {
      if (this.sortOrder.value === 'DISTANCE') {
        results = this.shops
          .concat()
          .sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
      } else {
        // order of shops returned from tpar
        results = this.shops;
      }
    } else {
      if (this.sortOrder.value === 'DISTANCE') {
        results = this.filteredShops
          .concat()
          .sort((a, b) => parseFloat(a.distance) - parseFloat(b.distance));
      } else {
        results = this.filteredShops
          .concat()
          .sort((a, b) => b.performanceScore - a.performanceScore);
        results.forEach((shop, index) => {
          // shops with performance score 0 need to be placed in 3rd position like how tpar handle when more than 3 shops in the results
          if (results.length > 3 && shop.performanceScore === 0) {
            // it will relocate the shop from last position to third position
            results.splice(index, 1)
            results.splice(2, 0, shop)
          }
        })
      }
    }
    return results;
  }

  resetAll() {
    this.reset = true;
    this.filteredCount = this.shops.length;
  }

  getMapInfo(): Marker {
    console.log('INFO: geocoding started for: ' + this.zip);
    // console.log('zip', this.zip);
    if (!this.isZipCodeSearch && this.urlParams.lat && this.urlParams.lng) {
      this.setYouAreHereMarker(this.urlParams.lat, this.urlParams.lng);
    } else if (this.zip) {
      this.getLatLan(this.zip).subscribe(
        result => {
          this.setYouAreHereMarker(result['lat'](), result['lng']());
        },
        error => console.log(error),
        () => console.log('geocoding completed!')
      );
    }
    this.getShopDetails(3);
    return this.youAreHereMarker;
  }

  private setYouAreHereMarker(lat, lng) {
    const theLatLng = { lat: lat, lng: lng };
    let theRadius = 8; // default as 8
    if (this.isNameSearch){
      theRadius = 13;
    } else if (this.urlParams.radius) {
      const temp = Number(this.urlParams.radius);
      if (temp <= 50 && temp > 25) {
        theRadius = 9;
      } else if (temp <= 25) {
        theRadius = 10;
      }
    }
    this.__zone.run(() => {
      this.youAreHereMarker = {
        latlng: theLatLng,
        radius: theRadius,
      };
    });
  }

  getShopDetails(initPlaceNum: number): any {
    if (this.sortedShops.length < initPlaceNum) {
      initPlaceNum = this.sortedShops.length;
    }
    for (let i = 0; i < initPlaceNum; i++) {
      if(!this.sortedShops[i].placeDetails){
        setTimeout(() => {
          this.googleSearchService.getplaceId(this.sortedShops[i], i);
        }, 10);
      }
    }
  }

  getLatLan(address: string) {
    console.log('INFO: geocoding address:', address);
    const geocoder = new google.maps.Geocoder();
    return new Observable(observer => {
      geocoder.geocode({ address: address }, function (results, status) {
        if (status === google.maps.GeocoderStatus.OK) {
          observer.next(results[0].geometry.location);
          observer.complete();
        } else {
          console.log('Error: ', results, ' & Status: ', status);
          observer.error(`failed to retrieve geolocation for ${address}`);
          observer.complete();
        }
      });
    });
  }

  navigateBackToReferer() {
    this.windowRef.navigateTo(this.urlResolver.getHubRedirectUrl());
  }

  onShopConfirmation() {
    this.isConfirmation = true;
    this.sendRepairAssignments();
  }

  private routeToConfirmationPage() {
    const CLAIM_ID = this.urlParams.externalClaimId;
    const CLIENT_ID = this.urlParams.externalClientId;
    const ROLE = this.urlResolver.getRole();

    const shopInfoToPass: ShopInfo = {
      shopName: this.sortedShops[this.activeShopIndex].name,
      street: this.sortedShops[this.activeShopIndex].address,
      city: this.sortedShops[this.activeShopIndex].city,
      phone: this.sortedShops[this.activeShopIndex].phone,
      state: this.sortedShops[this.activeShopIndex].state,
      zipCode: this.sortedShops[this.activeShopIndex].postalCode,
      placeId: this.sortedShops[this.activeShopIndex].placeDetails?.placeId,
    };

    const coverageInfoToPass: CoverageInfo = {
      code: this.coverageCode,
      title: this.coverageTitle,
      coverageValues: this.coverageValues,
    };

    this.shopInfoService.setShopInfo(shopInfoToPass);
    this.coverageInfoService.setCoverageInfo(coverageInfoToPass);

    this.router.navigate([
      'confirm/claim/' + CLAIM_ID + '/role/' + ROLE + '/client/' + CLIENT_ID,
    ]);
  }

  private sendRepairAssignments() {
    // SPARKL Logging
    const assignmentType = this.isEstimateAssist ? "estimate assist" : "select service";
    let loggingDetails = [
      SFLoggerFields.CALLED_SERVICE, SFLoggerFields.REPAIR_EXPERIENCE_API,
      SFLoggerFields.HTTP_REQUEST, SFLoggerFields.REP_EXP_REPAIR_ASSIGNMENT,
      SFLoggerFields.ASSIGNMENT_TYPE, assignmentType
    ];

    const repairAssignmentObj: RepairAssignment = new RepairAssignment();
    const isAgent = this.storageService.getSessionStorage('isAgent');
    if (isAgent === 'true') {
      repairAssignmentObj.channelTypeCode = 'AG';
    } else {
      repairAssignmentObj.channelTypeCode = 'CT';
    }
    repairAssignmentObj.estimatingId = this.shopToUseForClaim.estimatingId;
    repairAssignmentObj.vehicleNumber = this.clientService.getVehicleNumber();
    repairAssignmentObj.participantType = this.role;
    repairAssignmentObj.closedClaim = this.clientService.getClosedClaimIndicator().toString();
    this.repairExperienceService
      .sendRepairAssignments(
        this.isEstimateAssist,
        this.urlParams.externalClaimId,
        this.urlParams.externalClientId,
        this.clientService.getParticipantId(),
        repairAssignmentObj
      )
      .subscribe({
        next: status => {
          if (status === '1') {
            this.interactionsService.setRepairShopId(this.shopToUseForClaim.id);
            this.oneX.Modal.hideModal();
            this.isFailedConfirmation = false;

            // SPARKL Logging
            const updateContext = [SFLoggerFields.ASSIGNMENT_COUNT, this.assignmentCount];
            this.sfLoggerService.setContext(updateContext);
            this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_SUCCESS, "SUCCESS - RE API - POST /repairassignment", loggingDetails);

            this.routeToConfirmationPage();
          } else {
            this.isFailedConfirmation = true;
          }
        },
        error: error => {
          console.log(error);

          // SPARKL Logging
          loggingDetails.push(
            SFLoggerFields.LOG_LEVEL, SFLoggerFields.ERROR,
            SFLoggerFields.ENDPOINT, error.url,
            SFLoggerFields.HTTP_RESPONSE, error.status.toString(),
            SFLoggerFields.ERROR_MESSAGE, error.statusText
          )
          this.sfLoggerService.sendLog(SFLoggerMessageIds.RA_DOWNSTREAM_ERROR, "ERROR - RE API - POST /repairassignment", loggingDetails);

          this.isFailedConfirmation = true;
        },
      });
  }

  getCoverageDetail() {
    this.clientService
      .getCoverageDetail()
      .pipe(
        takeUntil(this.ngUnsubscribe),
        rxFilter(detail => !!detail)
      )
      .subscribe(
        coverage => {
          this.coverageCode = coverage.code;
          this.coverageTitle = coverage.title;
          this.coverageValues = coverage.coverageValues;
        }
      );
  }

  private isRefreshed(searchedResultFromSessionStorage): boolean {
    return (
      searchedResultFromSessionStorage &&
      !this.searchResultsService.getSearchResultsResponse()
    );
  }

  private isBookMarked(searchedResultFromSessionStorage): boolean {
    return (
      !searchedResultFromSessionStorage &&
      !this.searchResultsService.getSearchResultsResponse()
    );
  }

  hideNoShopsFoundCard() {
    this.isNoShopsFoundVisible = false;
  }

  showFooter() {
    this.footerDisplayService.changeFooterDisplay(true);
    setTimeout(function () {
      this.window.scroll({ top: 2500, left: 0, behavior: 'smooth' });
    }, 0);
    this.mockFooter.nativeElement.hidden = true;
  }

  showSelectServiceModal() {
    this.activeElem = this.document.activeElement as HTMLElement;
    this.selectServiceModal.show();
    this.selectServiceModalHidden = this.selectServiceModal.isHidden();
    document.getElementById('oneX-header').setAttribute('aria-hidden', 'true');
    setTimeout(() => this.selectServiceFocusElem.nativeElement.focus(), 0);
  }

  hideSelectServiceModal() {
    this.selectServiceModal.hide();
    this.selectServiceModalHidden = this.selectServiceModal.isHidden();
    document.getElementById('oneX-header').setAttribute('aria-hidden', 'false');
    this.activeElem.focus();
  }

  showEstimateAssistModal() {
    this.oneX.Modal.showModal(this, 'modal-estimate-assist');
    this.dataAnalyticsService.sendPageData('estimate-assist-modal');
  }

  isMobilePortraitSplitscreen() {
    return (
      this.pageState === ViewState.MOBILE_PORTRAIT_MAPONLY ||
      this.pageState === ViewState.MOBILE_LANDSCAPE_MAPONLY ||
      this.pageState === ViewState.MOBILE_PORTRAIT_SPLITSCREEN
    );
  }

  ngAfterViewInit() {
    this.oneX.addElement(this.shopSelectorRef.nativeElement);
    this.oneX.Icons.init();
    this.oneX.Modal.init();
    this.oneX.ExpansionPanel.init();
    this.oneX.Filter.init();

    if (!this.urlParams.externalClaimId) {
      this.dataAnalyticsService.sendPageData('search-results');
    } else {
      if (this.isEstimateAssist) {
        this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('estimate-assist-search-results', this.clientService.assignmentCount));
      } else {
        this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('search-results', this.clientService.assignmentCount));
      }
    }

    // medallia survey support
    this.clientService
      .getClaimNumber()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(claimNumber =>
        this.dataAnalyticsService.setClaimNumber(claimNumber)
      );

    // setTimeout(() => {
    if ((this.medallia = window['KAMPYLE_ONSITE_SDK'])) {
      this.medallia.updatePageView();
    }

    document.getElementById('address').focus();
  }

  hasClaimAndClient() {
    return (this.urlResolver.getClientId() !== undefined && this.urlResolver.getClaimId() !== undefined) ? true : false;
  }

  returnToListView() {
    this.showShopDetails = false;
    if (!this.urlParams.externalClaimId) {
      this.dataAnalyticsService.sendPageData('search-results');
    } else {
      if (this.isEstimateAssist) {
        this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('estimate-assist-search-results', this.clientService.assignmentCount));
      } else {
        this.dataAnalyticsService.sendPageData(this.dataAnalyticsService.setPageDataWithCount('search-results', this.clientService.assignmentCount));
      }
    }
  }

  goBack() {
    if (this.isDeviceMobile && this.showShopDetails) {
      this.returnToListView();
    } else if (this.clientService.assignmentCount > 0) { // always go back to ra 1.0 if secondary assignment
      this.router.navigate(['shopsearch'], {queryParams: this.urlResolver.getQueryParamsForUrl()});
    } else if (this.cookieService.get('optimizely_ch2_flag') === 'true' || this.cookieService.get('from-ra-landing-b') === 'true') {
      this.router.navigate(['landing'], {queryParams: this.urlResolver.getQueryParamsForUrl()});
    } else {
      this.router.navigate(['shopsearch'], {queryParams: this.urlResolver.getQueryParamsForUrl()});
    }
  }

  ngOnDestroy() {
    this.oneX.removeElement(this.shopSelectorRef.nativeElement);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
